<template>
  <div class="transportation-accounts-vehicles-realtime">
    <div v-if="$router.currentRoute.name === 'transportation-accounts-vehicles-realtime' && isComponentValid()" v-resize="onResize">
      <div id="headerElement" ref="headerElement">
        <div class="layout row justify-end align-center mx-2">
          <div class="layout row justify-end align-center" v-if="isValidToRunSimulator()">
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">fal fa-info-circle</v-icon>
                </template>
                <span>Simulations are only usable on development or staging servers<br />and will only run for a maximum of 15 minutes</span>
              </v-tooltip>
            </div>
            <div v-show="transportationProfileSimulatorIds.length" class="ml-2 v-select--fixed-width" style="width: 200px;">
              <v-select
                clearable
                :items="transportationProfileSimulatorIds"
                label="Active simulator(s)"
                :placeholder="(transportationProfileSimulatorIds.length > 0 ? transportationProfileSimulatorIds.length - 1 : '0') + ' simulator(s)'"
                v-model="selectedTransportationProfileSimulatorId"
              />
            </div>
            <div class="ml-2">
              <v-btn fab small title="Check for active simulators" @click="getSimulatorIds"><v-icon small>fal fa-sync</v-icon></v-btn>
            </div>
            <div>
              <v-btn flat v-show="!selectedTransportationProfileSimulatorId" @click="modalSimulateSelectDialog = true"><v-icon small  :class="transportationProfileSimulatorIds.length ? 'fa-spin' : ''">fal fa-cog</v-icon> &nbsp;Add simulator</v-btn>
              <v-btn flat v-show="selectedTransportationProfileSimulatorId" @click="stopTransportationProfileSimulator"><v-icon small :class="transportationProfileSimulatorIds.length ? 'fa-spin' : ''">fal fa-cog</v-icon> &nbsp;Stop Simulation</v-btn>
            </div>
          </div>
          <div class="ml-2 v-select--fixed-width" style="width: 300px;">
            <v-autocomplete
              @change="transportationProfilesSearchChanged"
              :loading="isTransportationProfilesLoading"
              :items="transportationProfiles"
              :search-input.sync="transportationProfilesSearchKey"
              hide-no-data
              no-filter
              clearable
              return-object
              label="Track a vehicle"
              placeholder="Filter by licence plate, driver name"
            >
              <template slot="item" slot-scope="{ item }">
                <div style="width: 100%">
                  <div>{{item.licensePlateNumber}}</div>
                  <div v-if="item.lastCheckedIn" class="layout row justify-start align-center">
                    <div><v-icon small>fal fa-location-circle</v-icon></div> &nbsp;<div><small>Last checked in: {{item.lastCheckedIn}}</small></div>
                  </div>
                  <div class="layout row justify-start align-center">
                    <div><v-icon small>fal fa-address-card</v-icon></div> &nbsp;<div><small><span v-show="item.currentDriverName">{{item.currentDriverName}}</span><span v-show="!item.currentDriverName" style="color: #ccc">None assigned</span></small></div>
                  </div>
                  <hr style="width: 100%; border: 0; border-bottom: 1px solid #ccc" />
                </div>
              </template>
              <template slot="selection" slot-scope="{ item }">
                <div>
                  <div>{{item.licensePlateNumber}}</div>
                </div>
              </template>
            </v-autocomplete>
          </div>
          <div class="ml-3" style="max-width: 200px;">
            <v-select
              clearable
              return-object
              :items="regions"
              label="Center on region"
              placeholder="Center by default"
              v-model="selectedRegion"
            />
          </div>
        </div>
      </div>
      <st-map
        v-if="stMapApis"
        disable-search
        container-id="realtimeBusMonitor"
        :api="stMapApis"
        :mapbox-access-token="mapboxAccessToken"
        :maptiler-key="maptilerKey"
        :track-bus-id="selectedTransportationProfileId"
        :on-bus-cluster-click-callback="onBusClusterClickCallback"
        :on-bus-marker-click-callback="onBusMarkerClickCallback"
        :on-buses-updated="onBusesUpdated"
        :on-bounds-changed="onBoundsChanged"
        :on-buses-init="onBusesInit"
        :marker-options="markerOptions"
        :default-center="selectedRegion ? selectedRegion.metadata.coordinates : null"
        :realtime-map-by-account="selectedAccount ? selectedAccount.id : true"
        :height-offset="offsetHeight" />
      <v-dialog v-model="modalBusDetailsDialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="lighten-2 grey text-xs-center">
            <h2>Vehicle <em v-if="busDetails">{{busDetails.licensePlateNumber}}</em></h2>
          </v-card-title>
          <v-card-text class="pa-2" style="max-height: 400px; overflow-y: auto">
            <div v-if="busDetailInProgress" class="layout row justify-center align-center">
              <v-progress-circular indeterminate :size="50" color="primary" />
            </div>
            <div v-if="!busDetailInProgress && modalBusDetailsError">
              <v-alert type="error" :value="true">
                <span v-html="modalBusDetailsError" />
              </v-alert>
            </div>
            <div v-if="!busDetailInProgress && !modalBusDetailsError && busDetails">
              <p class="text-xs-center"><small>Last checked in: {{busDetails.lastCheckedIn}}</small></p>
              <v-layout row wrap mb-2>
                <v-flex md4 sm6 pr-2 class="text-xs-right">
                  <strong>Driver</strong>
                </v-flex>
                <v-flex md8 sm6>
                  {{busDetails.driverName}}
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-2 v-if="busDetails.assistantName">
                <v-flex md4 sm6 pr-2 class="text-xs-right">
                  <strong>Assistant</strong>
                </v-flex>
                <v-flex md8 sm6>
                  {{busDetails.assistantName}}
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-2>
                <v-flex md4 sm6 pr-2 class="text-xs-right">
                  <strong>Equipment</strong>
                </v-flex>
                <v-flex md8 sm6>
                  {{busDetails.equipmentIdLabel}}
                  <div v-if="busDetails.equipmentInventoryLabel">({{busDetails.equipmentInventoryLabel}})</div>
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-2>
                <v-flex md4 sm6 pr-2 class="text-xs-right">
                  <strong>Uses routes</strong>
                </v-flex>
                <v-flex md8 sm6>
                  {{busDetails.usesRoutesLabel}}
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-2>
                <v-flex md4 sm6 pr-2 class="text-xs-right">
                  <strong>Account</strong>
                </v-flex>
                <v-flex md8 sm6>
                  {{busDetails.accountName}}
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-2>
                <v-flex md4 sm6 pr-2 class="text-xs-right">
                  <strong>Agency</strong>
                </v-flex>
                <v-flex md8 sm6>
                  {{busDetails.agencyLabel}}
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-2>
                <v-flex md4 sm6 pr-2 class="text-xs-right">
                  <strong>Vehicle type</strong>
                </v-flex>
                <v-flex md8 sm6>
                  {{busDetails.vehicleType}}
                </v-flex>
              </v-layout>
            </div>
          </v-card-text>
          <v-card-actions class="layout justify-end">
            <v-btn color="primary" flat @click="modalBusDetailsDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modalSimulateSelectDialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="lighten-2 grey text-xs-center">
            <h2>Add Simulator</h2>
          </v-card-title>
          <v-card-text class="pa-2">
            <p>Confirm running the vehicle simulator ("in Accra"). A vehicle should appear and run until stopped</p>
            <p>If transport is selected below, the available route assigned to it for the current date/time will run, otherwise a random route will be selected</p>
            <v-autocomplete
              @change="transportationProfileToSimulateSearchChanged"
              :loading="isTransportationProfilesLoading"
              :items="transportationProfilesForSimulator"
              :search-input.sync="transportationProfileToSimulateSearchKey"
              hide-no-data
              no-filter
              clearable
              return-object
              label="Transportation to use in simulation (optional)"
              placeholder="Filter by licence plate, driver name"
            >
              <template slot="item" slot-scope="{ item }">
                <div style="width: 100%">
                  <div>{{item.licensePlateNumber}}</div>
                  <div class="layout row justify-start align-center">
                    <div><v-icon small>fal fa-address-card</v-icon></div> &nbsp;<div><small><span v-show="item.currentDriverName">{{item.currentDriverName}}</span><span v-show="!item.currentDriverName" style="color: #ccc">None assigned</span></small></div>
                  </div>
                  <hr style="width: 100%; border: 0; border-bottom: 1px solid #ccc" />
                </div>
              </template>
              <template slot="selection" slot-scope="{ item }">
                <div>
                  <div>{{item.licensePlateNumber}}</div>
                </div>
              </template>
            </v-autocomplete>
          </v-card-text>
          <v-card-actions class="layout justify-space-between">
            <v-btn color="warning" flat @click="modalSimulateSelectDialog = false">Cancel</v-btn>
            <v-btn color="primary" @click="modalSimulateSelectDialog = false; runTransportationProfileSimulator()">Start Simulation</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<style>
  .v-select-list .v-list__tile {
    height: auto;
  }
</style>

<script>
import src from './src'
export default src
</script>
