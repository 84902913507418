import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getTransportationOwner ({ id, filter }) {
  return apiRequests.get('/v1/transportation-owner/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTransportationOwners (filter) {
  return apiRequests.get('/v1/transportation-owner/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalTransportationOwners (where) {
  return apiRequests.get('/v1/transportation-owner/count/join/', {
    params: { where },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTransportationOwnerProfiles (filter) {
  return apiRequests.get('/v1/transportation-owner-profile/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findArchivedTransportationOwners (filter) {
  return apiRequests.get('/v1/raw-transportation-owner/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalArchivedTransportationOwners (where) {
  return apiRequests.get('/v1/raw-transportation-owner/count/', {
    params: { where },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function archiveTransportationOwner ({ id }) {
  return apiRequests.delete('/v1/transportation-owner/' + id + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function unArchiveTransportationOwner ({ id }) {
  return apiRequests.put('/v1/transportation-owner/' + id + '/deleted/', null, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteTransportationOwner ({ id }) {
  return apiRequests.delete('/v1/transportation-owner/' + id + '/permanent/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function registerTransportationOwner (data) {
  return apiRequests.post('/v1/transportation-owner/registration/', data, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function verifyTransportationOwnerRegistration (data) {
  return apiRequests.put('/v1/transportation-owner/registration/verification', data, {
    headers: store.getters.getTokenId ? { 'Authorization': store.getters.getTokenId } : undefined
  }).then((result) => {
    return result.data
  })
}

function updateTransportationOwner ({ id, companyName, contactName, contactEmail, contactPhone, currentCountryId, equipmentTokens }) {
  return apiRequests.patch('/v1/transportation-owner/' + id + '/', { companyName, contactName, contactEmail, contactPhone, currentCountryId, equipmentTokens }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getTransportationOwner,
  findTransportationOwners,
  findTotalTransportationOwners,
  findTransportationOwnerProfiles,
  findArchivedTransportationOwners,
  findTotalArchivedTransportationOwners,
  archiveTransportationOwner,
  unArchiveTransportationOwner,
  deleteTransportationOwner,
  registerTransportationOwner,
  verifyTransportationOwnerRegistration,
  updateTransportationOwner
}
