import { mapApiRequests, apiRequests } from '../utilities/axios-factory'
import store from '../store'

function findBusStops (filter) {
  return mapApiRequests.get('/v1/bus-stop/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalBusStops (where) {
  return mapApiRequests.get('/v1/bus-stop/count/', {
    params: { where },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getBusStopJobStatus (routerId) {
  return mapApiRequests.get('/v1/bus-stop/sync-request-status/', {
    params: { routerId },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function startBusStopJob (routerId) {
  return mapApiRequests.post('/v1/bus-stop/sync-request/', { routerId }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function stopBusStopJob (routerId) {
  return mapApiRequests.delete('/v1/bus-stop/sync-request/', {
    params: { routerId },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function clearBusStopJobError (routerId) {
  return mapApiRequests.delete('/v1/bus-stop/sync-request/error/', {
    params: { routerId },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateBusStop ({ id, name }) {
  return mapApiRequests.patch('/v1/bus-stop/' + id + '/', { name }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createBusStopAudio ({ busStopId, locale, documentFile }) {
  const bodyFormData = new FormData()

  bodyFormData.append('busStopId', busStopId)
  bodyFormData.append('locale', locale)
  bodyFormData.append('document', documentFile)

  return apiRequests.post('/v1/bus-stop-audio/', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': store.getters.getTokenId
    }
  }).then((result) => {
    return result.data
  })
}

function updateBusStopAudio ({
  id,
  busStopId,
  locale,
  documentFile
}) {
  const bodyFormData = new FormData()

  if (busStopId) {
    bodyFormData.append('busStopId', busStopId)
  }

  if (locale) {
    bodyFormData.append('locale', locale)
  }

  if (documentFile) {
    bodyFormData.append('document', documentFile)
  }

  return apiRequests.patch('/v1/bus-stop-audio/' + id + '/', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': store.getters.getTokenId
    }
  }).then((result) => {
    return result.data
  })
}

function deleteBusStopAudio (id) {
  return apiRequests.delete('/v1/bus-stop-audio/' + id + '/', {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': store.getters.getTokenId
    }
  }).then((result) => {
    return result.data
  })
}

function getAllBusStopAudioByBusStopId (busStopId) {
  return apiRequests.get('/v1/bus-stop/' + busStopId + '/bus-stop-audio/', {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': store.getters.getTokenId
    }
  }).then((result) => {
    return result.data
  })
}

function getAllBusStopAudioByBusStopIds (busStopIds) {
  return apiRequests.get('/v1/bus-stop-audio/', {
    params: { filter: { where: { busStopId: { inq: busStopIds } } } },
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': store.getters.getTokenId
    }
  }).then((result) => {
    return result.data
  })
}

function getGenericBusStopAudio () {
  return apiRequests.get('/v1/bus-stop-audio/generic/', {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': store.getters.getTokenId
    }
  }).then((result) => {
    return result.data
  })
}

function deleteGenericBusStopAudio (settingId) {
  return apiRequests.delete('/v1/bus-stop-audio/generic/' + settingId + '/', {
    headers: {
      'Authorization': store.getters.getTokenId
    }
  }).then((result) => {
    return result.data
  })
}

function setGenericBusStopAudio ({ settingId, type, locale, documentFile }) {
  const bodyFormData = new FormData()

  if (settingId) {
    bodyFormData.append('settingId', settingId)
  }

  if (type) {
    bodyFormData.append('type', type)
  }

  if (locale) {
    bodyFormData.append('locale', locale)
  }

  if (documentFile) {
    bodyFormData.append('document', documentFile)
  }

  return apiRequests.patch('/v1/bus-stop-audio/generic/', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': store.getters.getTokenId
    }
  }).then((result) => {
    return result.data
  })
}

function getBusStopAudioSecureUrl (busStopAudioId) {
  return apiRequests.get(`/v1/bus-stop-audio/` + busStopAudioId + '/secure-url/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data && result.data.url
  })
}

function getGenericBusStopAudioSecureUrl (settingId) {
  return apiRequests.get(`/v1/bus-stop-audio/generic/` + settingId + '/secure-url/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data && result.data.url
  })
}

function getStRouteBusStops (stRouteId) {
  return apiRequests.get(`/v1/st-route/` + stRouteId + '/bus-stops/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getBusStopsByRouteIdList (routeIdList) {
  return apiRequests.get(`/v1/st-route/bus-stops/`, {
    params: { routeIdList },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function geocodeSearch ({ keywords, longlat, limit = 10 }) {
  return mapApiRequests.get(`/v1/geo-location/routes/geocode/`, {
    params: { keywords, longitude: longlat?.length && longlat[0], latitude: longlat?.length && longlat[1], limit, offset: 0 },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  findBusStops,
  findTotalBusStops,
  getBusStopJobStatus,
  startBusStopJob,
  stopBusStopJob,
  clearBusStopJobError,
  updateBusStop,
  deleteBusStopAudio,
  createBusStopAudio,
  updateBusStopAudio,
  getAllBusStopAudioByBusStopId,
  getAllBusStopAudioByBusStopIds,
  getBusStopAudioSecureUrl,
  getGenericBusStopAudioSecureUrl,
  getGenericBusStopAudio,
  setGenericBusStopAudio,
  deleteGenericBusStopAudio,
  getStRouteBusStops,
  getBusStopsByRouteIdList,
  geocodeSearch
}
