import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function findTransportationProfileRoutes (filter) {
  if (filter.where && !Object.keys(filter.where).length) {
    filter.where = undefined
  }
  return apiRequests.get('/v1/transportation-profile-route/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalTransportationProfileRoutes ({ where, join }) {
  if (where && !Object.keys(where).length) {
    where = undefined
  }
  return apiRequests.get('/v1/transportation-profile-route/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getProfileRoute ({ id, filter }) {
  return apiRequests.get('/v1/transportation-profile-route/' + id + '/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteTransportationProfileRoute ({ id }) {
  return apiRequests.delete('/v1/transportation-profile-route/' + id + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateTransportationProfileRoute (data) {
  return apiRequests.patch('/v1/transportation-profile-route/' + data.id + '/', data, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createTransportationProfileRoute ({ transportationProfileId, data }) {
  return apiRequests.post('/v1/transportation-profile/' + transportationProfileId + '/transportation-profile-route/', data, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getBusStopsForProfileRoute ({ id }) {
  return apiRequests.get('/v1/transportation-profile-route/' + id + '/bus-stops/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  findTransportationProfileRoutes,
  findTotalTransportationProfileRoutes,
  getProfileRoute,
  deleteTransportationProfileRoute,
  updateTransportationProfileRoute,
  createTransportationProfileRoute,
  getBusStopsForProfileRoute
}
